export default {
  searchFormData: [{
    span: 6,
    label: '名称(中文)',
    type: 'input',
    prop: 'ghs',
    placeholder: ''
  }, {
    span: 6,
    label: '商店',
    type: 'select',
    prop: 'cnName',
    options: [{
      text: '小坡岛店铺1',
      value: '00'
    }, {
      text: '小坡岛店铺2',
      value: '01'
    }]
  }, {
    span: 6,
    label: '排序优先级',
    type: 'input',
    prop: 'splx'
  }],
  tableDataItem: [{
    prop: 'shop',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'image',
    label: '',
    img: true,
    height: '100px',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'rank',
    label: '',
    Object: 'value'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '120'
  }],
  dialogBasicData: [{
    collname: '基本信息',
    alterFormData: [{
      span: 6,
      label: '商店',
      type: 'select',
      prop: 'gly',
      multiple: true,
      options: [{
        text: '1',
        value: false
      }, {
        text: '是',
        value: true
      }]
    }, {
      span: 24,
      label: '图标',
      type: 'avatar',
      prop: 'sjsj'
    }, {
      span: 6,
      label: '名称(中文)',
      type: 'input',
      prop: 'chineseName'
    }, {
      span: 6,
      label: '名称(英文)',
      type: 'input',
      prop: 'englishName'
    }, {
      span: 6,
      label: '排序优先级',
      type: 'input',
      prop: 'IDFrontPhoto'
    }]
  }],
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value'
  }, {
    prop: 'image',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'rank',
    label: '',
    Object: 'value'
  }]
}
