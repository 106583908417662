<template>
  <div>
    <el-card>
      <search-form
        ref="searchForm"
        :topVisible="true"
        :tabData="tabData"
        labelWidth="120px"
        :searchFormData="searchFormData"
        @search="search"
        @tabChange="tabChange"
      >
        <template #topBtn>
          <el-button type="primary" size="small" @click="handleClick('create')">{{ $t('text.add') }}</el-button>
        </template>
      </search-form>
      <create-table
        :autoWidth="true"
        @handleNewTab="handleNewTab($event, 'goodsManagerSubCategoriesDetail')"
        :operateVisible="operateVisible"
        ref="createTable"
        :loading="loading"
        :btnType="[]"
        :total_count="pageData.total_count ? pageData.total_count : 0"
        :current_page.sync="pageData.page"
        :papeSize.sync="pageData.per_page"
        @selectionChange="selectionChange"
        :topButtonVisible="true"
        :tableData="tableData"
        :tableDataItem="tableDataItem"
        @operate="handleClick"
        :pageVisible="true"
        :pageData="pageData"
        :checkBoxVisible="dropDataItem.length > 0"
        @handleSort="handleSort"
        @forwardToPage="forwardToPage"
        @onSwitchChange="handleSwitchChange"
      >
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{ `${$t('text.selected')}：${selectionData.length}` }}</span>
          <allButton
            :buttonDisabled="selectionData.length < 1"
            :buttonDataItem="dropDataItem"
            class="marginRight20"
            @chooseDropItem="chooseDropItem"
          ></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button
            class="margintop10"
            :class="item.action === 'view' ? 'operate_view' : ''"
            v-for="(item, index) in scope.row.member_actions.default_actions"
            :key="'default_actions' + index"
            :type="item.type && item.type === 'warn' ? 'danger' : 'primary'"
            size="mini"
            @click="handleClick(item, scope.row)"
            >{{ item.name }}</el-button
          >
          <el-button
            class="margintop10"
            v-for="(item, index) in scope.row.member_actions.custom_actions"
            :key="'custom_actions' + index"
            :type="item.type && item.type === 'warn' ? 'danger' : 'primary'"
            size="mini"
            @click="handleCusClick(item)"
            >{{ item.name }}</el-button
          >
        </template>
      </create-table>
    </el-card>
    <router-view />
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
