import { getShopSubCategoriesSetup, getShopSubCategoriesJson, deleteShopSubCategoriesId, postShopSubCategoriesOnline, postShopSubCategoriesOffline } from '@/services/product'
import searchForm from '@/components/searchForm'
import allButton from '@/components/allButton'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
import jsonData from './jsonData.js'
import loading from '@/utils/loading.json'

export default {
  name: 'managerProduct',
  components: {
    searchForm,
    createTable,
    allButton
  },
  mixins: [mixin],
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  created () {
    this.queryData(getShopSubCategoriesSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getShopSubCategoriesSetup)
    this.isActive = true
  },
  methods: {
    handleSwitchChange (data) {
      const { jsonItemData, rowData } = data
      this.$store.state.app.loading = this.$loading(loading)
      if (rowData[jsonItemData.prop].value) {
        postShopSubCategoriesOnline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      } else {
        postShopSubCategoriesOffline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      }
    },
    async forwardToPage (data, name) {
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    // 排序
    handleSort (val) {
      this.$refs.searchForm.onSubmit(val)
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getShopSubCategoriesJson(this.searchData).then(res => {
        if (res.data) {
          if (res.data) {
            this.tableData = []
            this.$nextTick(() => {
              this.tableData = res.data.objects
              this.tabData = res.data.scopes
              Object.assign(this.pageData, res.data.pagination)
            })
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'goodsManagerSubCategoriesAdd'
        })
      }
      if (type?.action === 'view') {
        // let obj = {}
        // this.$store.state.app.loading = this.$loading(loading)
        // const res = await getShopSubCategoriesId(data.id.value)
        // if (res.status === 200) {
        //   obj = res.data.shop_sub_category
        // }
        // this.$store.state.app.loading.close()
        // this.$message({
        //   type: 'success',
        //   message: this.$t('message.loadSuccess')
        // })
        // sessionStorage.setItem('dataOperate', JSON.stringify({ id: data && data.id.value ? data.id.value : undefined, type: type.action, obj, data }))
        this.$router.push({
          name: 'goodsManagerSubCategoriesDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'goodsManagerSubCategoriesEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteShopSubCategoriesId)
      }
    },
    // 切换伸缩框
    handleChange (val) {
      // console.log(val)
    },
    // 删除数据
    // deleteClick (data) {
    //   // mock操作
    //   this.$confirm('是否删除该数据?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.state.app.loading = this.$loading(loading)
    //     deleteShopSubCategoriesId(data.id.value).then(res => {
    //       this.$store.state.app.loading.close()
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       })
    //       this.search()
    //     }).catch(() => this.$store.state.app.loading.close())
    //   }).catch(() => {
    //   })
    // },
    // 数据的选择
    selectionChange (val) {
      this.selectionData = val
    }
  }
}
